<!--
* @description:
* @fileName ticketManage.vue
* @author hvv
* @date 2022/01/13 15:20:29
!-->
<template>
  <div></div>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  export default defineComponent({
    name: 'ticketManage',
    setup() {},
  })
</script>
<style lang="scss" scoped></style>
